import { useEffect } from "react"
import { navigate } from "gatsby"
import { track } from "@amplitude/analytics-browser"

export default () => {
  if (typeof window !== "undefined") {
    track("404", {
      url: window.location.href,
    })
  }

  useEffect(() => {
    navigate("/")
  }, [])
  return null
}
